// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-index-js": () => import("./../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-labs-index-js": () => import("./../src/pages/labs/index.js" /* webpackChunkName: "component---src-pages-labs-index-js" */),
  "component---src-pages-product-category-index-js": () => import("./../src/pages/product-category/index.js" /* webpackChunkName: "component---src-pages-product-category-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-post-js": () => import("./../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-faq-page-js": () => import("./../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-lab-post-js": () => import("./../src/templates/lab-post.js" /* webpackChunkName: "component---src-templates-lab-post-js" */),
  "component---src-templates-product-category-js": () => import("./../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-page-js": () => import("./../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-product-post-js": () => import("./../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

